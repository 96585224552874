@import url("https://use.typekit.net/urv3joi.css");
/* URL is generated via Adobe Fonts in a project called Bob in Becca's Adobe account */
@import url("intl-tel-input/build/css/intlTelInput.css");

html {
	min-width: 360px;
	font-size: 16px;
}

html,
body {
	margin: 0;
	background-color: #f6f7fc;
}

@media print {
	html,
	body {
		background-color: white;
	}
}
